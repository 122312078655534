@media screen and (max-width: 640px) {

  // Throw padding around box
  .content {
    padding: 5px !important;
    .p-24{
      padding: 0 !important;
    }
  }

  // Hide column in table
  .hide-xs{
    display:none !important;
  }

  .mat-form-field{
    flex: 1 1 100% !important;
    max-width : 100% !important;
  }
}
@media screen and (max-device-width : 1024px) {
  // Throw padding around box
  .content {
    padding: 5px !important;
    .p-24{
      padding: 0 !important;
    }
  }

  // Hide column in table
  .hide-xs{
    display:none !important;
  }

  .mat-form-field{
    flex: 1 1 100% !important;
    max-width : 100% !important;
  }
}