@mixin dashboards-theme($theme) {

  $background: map-get($theme, background);
  $is-dark: map-get($theme, is-dark);
  $foreground: map-get($theme, foreground);

  #dashboard, #dashboardEdevice {

    > .center {

      > .content {
        @if ($is-dark) {
          background: map-get($background, background);
        } @else {
          background: map-get($background, app-bar);
        }
      }
    }
  }
}