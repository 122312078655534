@mixin logs-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-logs {

    .header {

      .search-wrapper {
        background: map-get($background, card);

        .search {

          .mat-icon {
            color: map-get($foreground, icon);
          }

          input {
            background: map-get($background, card);
            color: map-get($foreground, text);
           }
        }
      }
    }
  }

  .green{
    fill: green;
  }
}


