@mixin predefined-actions-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-predefined-actions {

    .header {

      .search-wrapper {
        background: map-get($background, card);

        .search {

          .mat-icon {
            color: map-get($foreground, icon);
          }

          input {
            background: map-get($background, card);
            color: map-get($foreground, text);
          }
        }
      }
    }

    .content {
      .sidebar {
        .sidebar-content {
          .card {
            background: map-get($background, card);
          }
        }
      }
    }
  }
}
