// Import app and page component themes here to enable theming for them
@use '@angular/material' as mat;
// Import Fuse core library
@import "@fuse/scss/core";
@import "~ng-pick-datetime-ex/assets/style/picker.min.css";

@import "./main/apps/customers/customers.theme.scss";
@import "./main/apps/roles/roles.theme.scss";
@import "./main/apps/histories/histories.theme.scss";
@import "./main/apps/notes/notes.theme.scss";
@import "./main/apps/authorizations/authorizations.theme.scss";
@import "./main/apps/users/users.theme.scss";
@import "./main/apps/patients/patients.theme.scss";
@import "./main/apps/health-care-providers/health-care-providers.theme.scss";
@import "./main/apps/severities/severities.theme";
@import "./main/apps/routes/routes.theme.scss";
@import "./main/apps/manufacturers/manufacturers.theme";
@import "./main/apps/device-models/device-models.theme";
@import "./main/apps/sensor-types/sensor-type.theme";
@import "./main/apps/measurement-types/measurement-types.theme";
@import "./main/apps/dashboard/dashboards.theme";
@import "./shared/component/dashboard/patient/dash-patient.theme";
@import "main/apps/threshold/thresholds.theme";
@import "main/apps/devices/devices.theme";
@import "main/apps/measurements/measurements.theme";
@import "main/apps/physicians/physicians.theme";
@import "main/apps/hubs/hubs.theme";
@import "main/apps/kittings/kittings.theme";
@import "main/apps/logs/logs.theme";
@import "main/apps/translations/translations.theme";
@import "main/apps/action-statuses/action-statuses.theme";
@import "main/apps/action-categories/action-category.theme";
@import "main/apps/predefined-actions/predefined-actions.theme";
@import "main/apps/default-thresholds/default-thresholds.theme.scss";
@import "main/apps/predefined-notes/predefined-notes.theme";
@import "main/apps/reports/reports.theme";

@import "src/app/main/pages/authentication/login/login.theme";
@import "src/app/main/pages/authentication/twofactor/twofactor.theme";
@import "src/app/main/pages/authentication/forgot-password/forgot-password.theme";
@import "src/app/main/pages/authentication/reset-password/reset-password.theme";


@import "src/app/main/ui/cards/cards.theme";


// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (
        500: $dark-primary-text
    )
);

$fuse-black: (
    500: black,
    contrast: (
        500: $light-primary-text,
    )
);

$fuse-navy: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    A700: #2D323E,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
    $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
    $title: mat.define-typography-level(20px, 32px, 600),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.125, 400) // line-height must be unitless !!!
);

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------


// Define a mixin for easier access
@mixin components-theme($theme) {

    @include customers-theme($theme);
    @include authorizations-theme($theme);
    @include histories-theme($theme);
    @include notes-theme($theme);
    @include roles-theme($theme);
    @include users-theme($theme);
    @include patients-theme($theme);
    @include healthCareProviders-theme($theme);
    @include severities-theme($theme);
    @include routes-theme($theme);
    @include manufacturers-theme($theme);
    @include deviceModels-theme($theme);
    @include sensorTypes-theme($theme);
    @include measurements-types-theme($theme);
    @include dashboards-theme($theme);
    @include dashPatient-theme($theme);
    @include thresholds-theme($theme);
    @include devices-theme($theme);
    @include measurements-theme($theme);
    @include physicians-theme($theme);
    @include hubs-theme($theme);
    @include kittings-theme($theme);
    @include logs-theme($theme);
    @include translations-theme($theme);
    @include action-statuses-theme($theme);
    @include action-categories-theme ($theme);
    @include predefined-actions-theme ($theme);
    @include default-thresholds-theme($theme);
    @include predefined-notes-theme ($theme);
    @include reports-theme ($theme);

    // Pages
    @include login-theme($theme);
    @include twofactor-theme($theme);
    @include reset-password-theme($theme);
    @include forgot-password-theme($theme);

    // UI
    @include ui-cards-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme($yellow-light-theme-primary-palette, $yellow-light-theme-accent-palette, $yellow-light-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {

    // Generate the Angular Material theme
    @include mat.all-component-themes($yellow-light-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);

    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {

    // Generate the Angular Material theme
    @include mat.all-component-themes($blue-gray-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);

    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {

    // Generate the Angular Material theme
    @include mat.all-component-themes($pink-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);

    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// To hide table when there is no measurement, no thresholds...
.hide{
    display: none;
}

mat-cell{
    cursor:pointer;
}
